import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"

export default () => (
   <Layout>
     <SEO title="about" />
     <Card>
      <h2>About Me</h2>

         <p>My name is Daryl Metzler, a DevOps Engineer living the San Luis Obispo area.
         My wife Jennifer and I live in Paso Robles with our pets, Orion and Reese.</p>

         <h3 id="college"> College</h3>

         <p>I attended North Carolina State University (Wolfpack!) from 2006-2011.
         My Bachelors of Science is in Physics.
         While attending I spent the large majority of my free time studying Mathematics.
         Mathematics and Physics go well together, much like chocolate and peanut butter.</p>

         <p>I spent an almost unreasonable amount of time there immersing myself in learning C and Linux.
         At the time, Windows Vista was the alternative.
         Despite “growing up on” on Microsoft, scientific computing is done on UNIX.</p>

         <h3 id="the-army">The Army</h3>

         <p>The Job market was not kind in 2011.
         I decided to enlist in the US Army as an IT Specialist.
         I went to Basic Training in 2011, and Advanced Training early 2012.
         I spent 4 years at the Camp Roberts SATCOM station.</p>

         <h3 id="ifixit">iFixit/Dozuki</h3>

         <p>Currently, I am working as the DevOps Engineer for iFixit and Dozuki.
         This entails being point for systems admin and security tasks.
         Our application is relatively complex Ecommerce and CMS system written in PHP.</p>

         <p>My niche is the seam between the Backend PHP and the infrastructure.
         Our stack runs entirely on AWS, so I am in charge of a repository for configuring the various servers.
         About 30k lines of Bash and Ruby manage everything for our environments.
         This includes everything from networking, to storage and backups, to MySQL replication, to configuring the web proxies.</p>

         <h3 id="personal">Personal</h3>

         <p>I find fulfillment in building things.
         Perhaps that is why i enjoy cooking and photography so much.</p>

         <p>Being a voracious reader, I consume several technical blog feeds.
         Keeping up with the latest in technology and the open source community is a full time endeavour.</p>

         <p>I love learning new programming languages.
         Ruby is my favorite new language. You’re next Elixir.</p>

         <h3 id="contact-me">Contact me</h3>

         <p><a href="mailto:djmetzle@ncsu.edu">djmetzle@ncsu.edu</a></p>
     </Card>
   </Layout>
)
